@font-face {
  font-family: 'Proxima Nova';
  src: url('../../../../../assets/fonts/proxima_nova.otf') format('truetype');
}

.barcode {
  position: absolute;
  padding-bottom: 95px;
  padding-left: 90px;
}

.text {
  padding-top: 30px;
  font-size: 7pt;
  position: absolute;
  font-family: "Proxima Nova";
}

.sn {
  padding-bottom: 57px;
  padding-left: 143px;
}

.reference {
  padding-right: 118px;
}

.date {
  padding-left: 139px;
}
