.Active {
  color: #0e9104;
}

.Pending {
  color: #01a2ff;
}

.Completed {
  color: #0e9104;
}

.Suspended{
  color: #F25123;
}

.icon{ 
  width: 1rem;
}