.main {
    display: flex;
    flex-direction: column;
}
.block {
    width: 80vw;
    height: fit-content;
    border: dimgray 1px solid;
    margin: 15px;
    padding: 25px 10px 50px 20px;
    justify-content: space-around;
}

.preparationsBlock {
    height: fit-content;
    margin: 15px;
    justify-content: space-around;
}

.subBlockAfter {
    height: fit-content;
    justify-content: space-around;
    margin-bottom: 40px;
}

.afterBlock {
    display: flex;
    height: fit-content;
    justify-content: space-around;
    margin: 15px;
}

.subBlockMore {
    width: 75%;
    height: fit-content;
    justify-content: space-around;
}

.moreBlock {
    display: flex;
    height: fit-content;
    justify-content: space-around;
    margin: 15px;
}