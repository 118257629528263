.input {
  border-radius: 0;
  width: 10rem;
  height: 3rem;
}

.subject {
  width: 10rem;
}

.disposition {
  width: 20.5rem;
}



.delete {
  cursor: pointer;
}