.addIcon {
    margin-left: 10px;
}

.addIcon img{
    margin-right: 10px;
}

.customModel{
    margin-left: 5%;
    margin-top: 1rem;
    width: 98%;
  }
  .content{
    width: 90%;
  }