.input {
  border-radius: 0;
  width: 12rem;
  height: 3rem;
  margin-left: 1rem;
}

.item {
  background-color: #f2f5ff;
}

.list {
  cursor: pointer;
  max-height: 27em;
  overflow-y: auto;
}
