.input {
  width: 8rem;
  height: 3rem;
}
.study {
  width: 18rem;
}

.tId {
  width: 3rem;
}

.general {
  width: 8rem;
}

.detail{
  flex-basis: 33.33333%;
}