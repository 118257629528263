.info {
    margin-right: 50px;
}

.info button {
    padding: 0;
    color: black;
}

.circle {
    width: 60px;
    height: 60px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 30px;
    margin-right: 20px;
}