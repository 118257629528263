.table {
  font-size: 0.7rem;
}

.studies {
  width: 100rem;
}

.mainBox {
  display: flex;
  width: 600px;
  align-items: center;
  justify-content: space-between;
}

.inputStudy {
  height: 40px;
  width: 80px;
  margin-bottom: 0;
}

.inputPatient {
  height: 40px;
}

.inputSize {
  width: 100px;
  height: 40px;
  font-size: 12px;
  margin-top: 0.5rem;
  margin-right: 2px;
}

.checkboxText {
  font-size: 12px;
}

.checkboxBox {
  display: flex;
  justify-content: center;
}