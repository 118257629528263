:global(.card).wrapper {
    min-width: 380px;
    max-width: 100%;
  }
  
  .logoWrapper {
    width: 37.5%;
    min-width: 190px;
    margin: 0 auto;
  }
  
  .bgLogoWrapper {
    width: 35%;
  }
  
  .bottomLogo {
    width: 10rem;
    top: 2px;
    position: relative;
  }
  
  .divider {
    border-bottom: 1px solid var(--muted-color);
    height: 2px;
    margin-bottom: 0.375rem;
  }