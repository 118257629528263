.linesButtons {
  height: 70px;
  border-bottom: 1px solid #d6d6d6;
}

.boxTypeButtons {
  font-size: 12px;
}

.boxTypeButtons div:last-child {
  margin-top: 0 !important;
}

.locationButtons {
  font-size: 12px;
}

.locationButtons div:first-child {
  margin-top: 0 !important;
  flex-direction: column;
}

.boxBarcodeInput {
  width: 206px;
  height: 52px;
}

.freezerInput {
  width: 240px;
  height: 52px;
}

.rackInput {
  width: 160px;
  height: 52px;
}

.vendorInput {
  width: 435px;
  height: 52px;
}

.captureBox {
  margin-top: 10px;
  margin-left: 50px;
}

.rowLabels9 {
  margin-top: 70px;
}
.rowLabels9Left {
  margin-top: 85px;
}
.rowLabels14 {
  margin-top: 70px;
}

.rowLabels14Left {
  margin-top: 100px;
  margin-left: 34px;
}

.box9 {
  margin-left: 14px;
  margin-top: 68px;
}

.box14 {
  width: 322px;
  height: 322px;
  margin-top: 85px;
  margin-left: 1px;
}

.smallBox {
  border: 1px solid aquamarine;
}

.smallBox14 {
  width: 23px;
  height: 23px;
  font-size: 6px;
  border: 1px solid aquamarine;
}

.label {
  font-size: 12px;
}

.label14 {
  width: 23px;
  height: 23px;
  font-size: 8px;
}

.buttonSave {
  margin-top: 15px;
  color: #707070;
}

.buttonSave button {
  justify-content: center;
  width: 130px;
  height: 44px;
  color: #707070;
}

.line {
  padding-right: 50px;
  border-right: 1px solid grey;
  margin-top: 15px;
}

.lineRadio {
  border-right: 1px solid grey;
  height: 60px;
}

.boxTypeText {
  font-size: 12px;
}

.locationText {
  font-size: 12px;
}

.captureImage {
  position: relative;
  width: 360px;
  height: 360px;
}

.image {
  position: relative;
  width: 430px;
  height: 430px;
}

.captureBoxOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.captureBoxInfo {
  margin-top: 20px;
  margin-right: 10px;
}

.buttonsGroup {
  background: lightgray;
  border-radius: 15px;
  margin-top: 10px;
  padding: 5px;
}

.bottomButton {
  margin-left: 10px;
  width: 25px;
}

.leftButton {
  rotate: -90deg;
  margin-left: 10px;
  width: 25px;
}

.rightButton {
  rotate: 180deg;
  margin-left: 10px;
  width: 25px;
}

.topButton {
  rotate: 90deg;
  margin-left: 10px;
  width: 25px;
}

.adjustIcon {
  margin-right: 10px;
}

.spin {
  width: 7rem;
  height: 7rem;
  position: absolute;
  text-align: center;
  z-index: 2;
}

.spinner9 {
  margin-left: 15%;
  margin-top: 10%;
}

.spinner14 {
  margin-left: 26%;
  margin-top: 15%;
}
.customModel {
  margin-left: 5%;
  margin-top: 1rem;
  width: 98%;
}

.content {
  width: 90%;
}
