.main {
  border: #707070 solid 1px;
  max-height: 197px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.formModal {
  padding-bottom: 0;
}
.volume {
  margin-right: 5px;
}
.subMain {
  display: flex;
  justify-content: space-between;
}

.subMain:last-child {
  margin-top: 10px;
}

.subjectBox {
  margin-top: 20px;
  height: 52px;
  width: 184px;
}

.radioLabel {
  color: #707070;
  font-size: 14px;
}

.textarea {
  height: 80px;
  width: 530px;
}

.addIcon {
  margin-left: 10px;
}

.addIcon img {
  margin-right: 10px;
}

.top {
  top: -3px;
  right: 0;
}

.delete {
  cursor: pointer;
}

.input {
  width: 10rem;
  height: 3rem;
}
.general {
  width: 12rem;
}
.suffix {
  margin-right: 1rem !important;
  margin-left: 0.25rem !important;
  margin-top: -1px !important;
}
