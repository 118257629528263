.main {
    border: #707070 solid 1px;
    max-height: 197px;
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
}

.subMain {
  display: flex;
  justify-content: space-between;
}

.input {
  border-radius: 0;
  width: 7rem;
  height: 3rem;
  margin-left: 1rem;
}

.customModel{
  margin-left: 5%;
  margin-top: 1rem;
  width: 98%;
}
.content{
  width: 90%;
}

.main {
  border: #707070 solid 1px;
  max-height: 197px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.formModal {
  padding-bottom: 0;
}

.subMain {
  display: flex;
  justify-content: space-between;
}

.subMain:last-child {
  margin-top: 10px;
}

.subjectBox {
  margin-top: 20px;
  height: 52px;
  width: 184px;
}

.radioLabel {
  color: #707070;
  font-size: 14px;
}

.textarea {
  height: 80px;
  width: 513px;
  
}

.addIcon {
  margin-left: 10px;
}

.addIcon img {
  margin-right: 10px;
}

.top {
  top: -3px;
  right: 0;
}

.delete {
  cursor: pointer;
}

.select {
  height: 30px;
}
.input {
  width: 10rem;
  height: 3rem;
}
.general {
  width: 12rem;
}
.suffix {
  margin-right: 1rem !important;
  margin-left: 0.25rem !important;
  margin-top: 1.3rem !important;
}
