.wrapper {
    background: transparent linear-gradient(0deg, #bdbfc5 0%, #cacdd6 100%) 0% 0% 
        no-repeat padding-box;
}

.cardWrapper {
    background-color: #f9f9f9;
}
  
@media (max-width: 575.98px) {
    .cardWrapper {
        float: none;
        width: 97.33%;
        height: 49.13%;
        min-height: 30rem !important;
    }
}
  