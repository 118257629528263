#button {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    border-radius: 6px;
    border-color: transparent;
    outline: none !important;
    box-shadow: none !important;
  }
  
  .iconDivider {
    margin: 0 0.2rem;
  }
  
  .button:hover:enabled {
    filter: brightness(110%);
  }
  
  .button:active:enabled {
    filter: brightness(90%);
  }
  