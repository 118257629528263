.clinical {
  width: 95%;
}

table thead th  {
  position: sticky;
 
  top: 0;
  z-index: 2;
}

.editIcon {
  width: 1rem;
}

.table-container {
  max-width: 95%; /* Adjust as needed */
  overflow-x: auto;
  white-space: nowrap; /* Prevent table from wrapping */
}
 
