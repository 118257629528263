/* Shared styles */

.box {
  user-select: none;
}

.box:not(.disabled) :global(.custom-control-label) {
  cursor: pointer;
}

.box :global(.custom-control-label::before) {
  box-shadow: none !important;
  transition: none;
}

/* Checkbox styles */
.box.checkbox :global(.custom-control-label::before) {
  background-color: #fff !important;
  border: 2px solid #707070 !important;
  border-radius: 3px;
}

/* Checkbox Checked */
.box.checkbox
  :global(.custom-control-input:checked ~ .custom-control-label::before) {
  background-color: #fd9441 !important;
  border-color: #fd9441 !important;
}

/* Checkbox Disabled */
.box.checkbox
  :global(.custom-control-input:disabled ~ .custom-control-label::before) {
  opacity: 0.6;
}

/* Radio  styles */
.box.radio :global(.custom-control-label::before) {
  background-color: #fff !important;
  border: 2px solid #707070 !important;
}

/* Radio Checked */
.box.radio
  :global(.custom-control-input:checked ~ .custom-control-label::before) {
  border-color: #fd9441 !important;
}

.box.radio
  :global(.custom-control-input:checked ~ .custom-control-label::after) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='%23fd9441'/></svg>") !important;
  background-size: 75%;
}

/* Radio Disabled */
.box.radio
  :global(.custom-control-input:disabled ~ .custom-control-label::before) {
  opacity: 0.6;
}
