.suffixBox {
    margin-left: 15px;
}

.main {
    border: #707070 solid 1px;
    max-height: 197px;
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
}

.top {
    top: -3px;
    right: 0;
}

.delete {
    cursor: pointer;
}

.inputBox {
    margin-top: 15px;
}

.inputBoxImg {
    margin-top: 15px;
}

.input {
  border-radius: 0;
  width: 7rem;
  height: 3rem;
  margin-left: 1rem;
}

.subject {
  width: 10rem;
}

.location {
  width: 12rem;
}

.suffix {
  margin-right: 1rem !important;
  margin-left: 0.25rem !important;
  margin-top: 1.3rem !important;
}

.device {
  width: 400px;
  height: 480px;
  margin-bottom: 0;
  margin-left: 48px!important;
}

.deviceButton{
    width: 200px;
    height: 100px;
    margin-bottom: 0;
    margin-left: 48px!important;
}

.linkButton{
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.img{
  cursor: pointer;
}

.image {
    transform: rotateY(180deg);
    transform: scaleY(-1);
}
