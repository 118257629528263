.input {
  border-radius: 0;
  width: 7rem;
  height: 3rem;
  margin-left: 1rem;
}

.subject {
  width: 10rem;
}

.location {
  width: 11rem;
}

.suffix {
  margin-right: 1rem !important;
  margin-left: 0.25rem !important;
  margin-top: -1px !important;
}

.volume {
  margin-right: 5px;
}
.top {
  top: 0;
  right: 0;
}

.delete {
  cursor: pointer;
}

.device {
  width: 8rem;
  height: 4.5rem;
  margin-bottom: 0;
  margin-left: 13rem!important;
}

.linkButton{
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.img{
  cursor: pointer;
}

.image{
  transform: rotateY(180deg);
  transform: scaleY(-1) scaleX(-1);
}

.spinner {
    width: 20px;
    height: 20px;
}

.modalHeight {
    height: 80%;
}
.textarea {
  width: 430px;
}
