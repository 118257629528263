.table {
  border: 1px solid rgb(158, 158, 158);
  font-size: 0.7rem;
}
.table th {
  font-weight: normal;
}

.table td {
  padding: 0.25rem;
}

.btn {
  height: 2em;
  width: 5em;
}

.pagination > li > a,
.pagination > li > span {
  color: black;
  border-width: 0;
  border-radius: 0;
  margin-top: 4px;
}

.text {
  font-size: 0.8rem;
  margin-top: 0.8em;
}

.border {
  border-right: solid 1px lightgray;
}