.table {
  font-size: 0.7rem;
}

.kitTypes {
  width: 100rem;
}

.input {
  border-radius: 0;
  width: 10rem;
  height: 3rem;
  margin-left: 1rem;
}
.delicon{
  width: 1.5rem;
}
.ifu {
  width: 15rem!important;
}

.version {
  /* width: 7rem!important; */
}
