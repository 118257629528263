@import "bootstrap/dist/css/bootstrap.min.css";
@import "./forms.css";

/* Util classes*/
.cursor-pointer {
  cursor: pointer;
}

.absolute-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

p.app-hint,
.app-hint {
  background-color: #eef2ff !important;
  color: #435fc5 !important;
}


.app-link {
  color: #2c449a !important;
  text-decoration: underline !important;
}

a {
  text-decoration: none;
}

.font-md {
  font-size: 0.875rem;
  line-height: 1.3;
}

.font-sm {
  font-size: 0.75rem;
}

.capitalized {
  text-transform: uppercase;
}

.capitalized-first {
  text-transform: capitalize;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.icon-xm {
  width: 1.5rem;
}

.icon-sm {
  width: 3rem;
}

.icon-md {
  width: 4.5rem;
}

.icon-lg {
  width: 6rem;
}

.icon-xl {
  width: 8rem;
}