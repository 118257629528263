.mainBox {
    margin-left: 40px;
}

.dataTypeText {
    margin: 24px 50px 20px 0;
    font-size: 12px;
}

.dataTypeButtons {
    font-size: 12px;
}

.otherInputs {
    font-size: 12px;
    margin-bottom: 20px;
}

.buttonLink {
    margin-left: 15px;
    color: #707070;
}

.buttonLink button {
    justify-content: center;
    width: 130px;
    height: 44px;
    color: #707070;
}

.buttonLinkData button {
    color: black;
}

.buttonStyle {
    position: relative;
}

.buttonStyle::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
}

.buttonLinkDataBox {
    margin-top: 30px;
}

.linkBoxInput {
    width: 652px;
    height: 52px;
}

.linkLabel {
    font-size: 12px;
    margin-bottom: 20px;
}

.cellInput {
    width: 98px;
    height: 52px;
    margin-right: 15px;
}
