.shipments {
  width: 100rem;
}

.table {
  font-size: 0.8rem;
}

.input {
  border-radius: 0;
  width: 9rem;
  height: 3rem;
  margin-left: 1rem;
  font-size: 0.9rem;
}

.linkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
