.description {
  font-size: 0.8em;
  margin-top: 0.3em;
}

.form {
  width: 40rem;
  border: 0.5px solid black;
}

.first-button {
  margin-right: 0.5em;
}

.label {
  font-weight: 700;
}

.line {
  margin: 0;
}

.x-button {
  border-color: transparent;
}