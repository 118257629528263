.img {
  transform: scaleY(-1) scaleX(-1);
  left: -97px;
  position: relative;
  object-fit: fill;
  width: 200%;
}
.imgContainer 
{
    overflow: hidden;
    width: 146px;
    height: 571px;
}

.border{
    border-top: 1px solid lightgrey!important;
}
