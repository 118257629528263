.app-form-input::placeholder {
  color: #b2b0b0 !important;
}

.app-form-input.placeholder {
  background: #f9f9f9;
}

.app-form-input.invalid {
  border: 2px solid #ff3e58 !important;
}

.app-form-input.disabled {
  background: #e9e9e9 !important;
  box-shadow: none !important;
}

.app-form-input.with-text.disabled {
  border: 1px solid #b2b0b0 !important;
  background: #feeede !important;
  box-shadow: none !important;
}

.app-form-input.textarea {
  min-height: 4.3125rem;
  fill: violet;
}

.app-form-input-dropdown {
  color: #878787 !important;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.app-form-input-dropdown.value-selected {
  color: #000 !important;
}

.app-form-input-dropdown:focus {
  box-shadow: none !important;
}
